#list {
    font-size: 2rem;

    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 1rem;

        thead {
            td {
                font-weight: 500;
                padding: 0 1.5rem 1.5rem 1.5rem;
                vertical-align: top;

                > span {
                    width: 100px;
                    display: flex;
                    justify-content: center;
                }
            }
        }
        tbody {
            tr:nth-child(even) {
                background: rgba(255, 255, 255, 0.08);
            }
            tr:nth-child(odd) {
                background: rgba(255, 255, 255, 0.15);
            }
            tr:hover {
                background: rgba(255, 255, 255, 0.22);
            }
            td {
                padding: 1.5rem;
                position: relative;
                max-height: 50px;
            }
        }
    }
}
