#simulation-main-view#simulation-main-view {
    padding: 5rem;

    table {
        thead {
            font-size: 24px;
            tr {
                td {
                    border-bottom: 1px solid white;
                }
            }
        }

        td:nth-of-type(3) {
            width: 15%;
            min-width: 160px;
        }

        tbody {
            tr {
                background-color: var(--dark-background-primary);
                td {
                    padding-top: 1rem;
                    padding-bottom: 1rem;

                    svg {
                        margin-left: 4px;
                    }
                }
            }
        }
    }
}
