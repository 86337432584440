#top-navbar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 7.5vh;
    font-size: 16px;
    background-color: var(--dark-grayscale-800-bg-primary);

    div {
        display: flex;
        align-items: center;
    }

    #svg {
        height: 4vh;
    }

    .username-container {
        margin: 16px;
        padding: 0.5rem 1rem;
        cursor: pointer;
        .user-icon {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            border-radius: 100%;
            background-color: var(--dark-grayscale-400);
            margin-right: 1rem;
            .user-status {
                position: absolute;
                height: 8px;
                width: 8px;
                border-radius: 100%;
                bottom: 2px;
                right: 0px;

                &.active {
                    background-color: var(--light-blue-base);
                }
            }
        }
        .expand {
            margin-left: 1.5rem;

            &.rotate {
                transform: rotate(180deg);
            }
        }
    }

    .szh-menu-container {
        top: 0;
        .menu {
            top: 7.5vh !important;
            z-index: 10;
        }
    }

    .menu-item {
        position: relative;
        padding: 0;
        background-color: var(--dark-grayscale-400);
        z-index: 1;
        cursor: pointer;

        &:hover {
            background-color: #343e44;
        }
        &:focus-visible {
            outline: none;
        }

        .menu-item-content {
            padding: 12px 26px;
            display: flex;
            align-items: center;
            min-height: 50px;
            min-width: 140px;

            span.icon {
                margin-right: 1rem;
                svg {
                    width: 2rem;
                    height: 2rem;
                    position: relative;
                }
            }
        }

        /* a {
            display: flex;
            flex-direction: row;
            align-items: center;
            span.icon {
                margin-right: 1rem;
                svg {
                    width: 2rem;
                    height: 2rem;
                    position: relative;
                }
            }
        } */
    }
}
