.time-picker {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: fit-content;
    // border: 1px solid red;

    .time-picker-element {
        .time-picker-input {
            width: 40px;
            padding: 6px 8px;
        }

        .time-picker-chevron {
            display: flex;
            justify-content: center;
            align-items: center;

            &.plus {
                transform: rotate(180deg);
            }

            svg {
                cursor: pointer;
            }
        }
    }
}
