.DatePicker-container {
    position: relative;
    width: 110px;
    height: 25px;

    .datetime-picker {
        background-color: transparent;
        margin: 0 2.5%;
        width: 0;

        .react-datetime-picker__wrapper {
            display: none;
            width: 10px;
            height: 25px;
        }

        abbr {
            font-family: 'KONE Information', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
                'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                sans-serif;
        }

        span {
            font-family: 'KONE Information', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
                'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                sans-serif;
        }

        div {
            border: 1;
        }

        .react-calendar__year-view .react-calendar__tile,
        .react-calendar__decade-view .react-calendar__tile,
        .react-calendar__century-view .react-calendar__tile {
            padding: 1rem 0.5em;
        }

        .react-datetime-picker__calendar {
            width: 24.5rem !important;
            top: 1.5rem !important;
        }

        .react-calendar {
            border: 0px;
            border-radius: 8px;
            width: 27rem;
            background: var(--dark-grayscale-800-bg-primary, #141414);
        }

        .react-calendar__navigation {
            & > button {
                background-color: transparent;
                color: white;
                padding: 0 12px;
                min-width: 20px;

                &:disabled {
                    color: var(--light-grayscale-200, #566066);
                }
            }
        }

        .react-calendar__month-view__weekdays {
            color: var(--light-grayscale-300, #aaafb3);
            abbr {
                text-decoration: none;
            }
        }

        .react-calendar__tile {
            font-size: small;
            border-radius: 4px;
            color: var(--light-grayscale-300, #aaafb3);
            min-width: 0;

            abbr {
                text-decoration: none;
            }

            &:disabled {
                background-color: transparent;
                color: var(--light-grayscale-200, #566066);
            }

            &.react-calendar__month-view__days__day--neighboringMonth {
                color: var(--light-grayscale-200, #566066);
            }

            &.react-calendar__tile--now {
                background-color: transparent;
            }

            &.react-calendar__tile--active {
                background-color: var(--kone-blue);
                color: white;
            }

            &:hover {
                background-color: var(--light-background-secondary, #f5f6f7);
                color: black;
            }
        }

        .react-calendar__navigation {
            font-size: medium;
            button {
                border-radius: 8px;
                &:disabled {
                    background-color: transparent;
                }
            }
        }
    }

    .on-top {
        cursor: pointer;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 2.5%;
        width: 100%;
        padding: 6px 8px;
        font-size: large;
        top: 0;
        left: 0;
        min-width: 110px;

        background-color: transparent;
        border-radius: 4px;
    }
}
