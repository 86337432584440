#sidebar-stats {
    .sim-platform-stats-container {
        display: flex;
        flex-direction: column;

        > label {
            display: inline-block;
            margin-bottom: 1rem;
        }
    }
}
