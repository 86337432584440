.gauge-container {
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;

    .gauge-charts-title {
        margin-bottom: 1rem;
    }

    .gauge-charts {
        height: 100% !important;
        width: 100%;
    }
}

.chart-container {
    padding: 2rem 3rem;
    flex: 1;
    background: var(--dark-background-primary, #11161a);

    .chart-title {
        font-size: 24px;
    }
}
