/*========================================================================
*      SCSS Styles for MetroLineOverview                                       
=========================================================================*/

#metroLineOverview {
    main {
        padding: 5rem;
        display: flex;
        flex-direction: column;
        background: #1c2226;
    }

    #title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        #metro-sign-container {
            display: flex;
            flex-direction: row;

            #metro-sign {
                background: var(--text-base);
                display: flex;
                width: 5.4rem;
                height: 5.4rem;
                border-radius: 0.4rem;
                text-align: center;
                margin-right: 1rem;

                span {
                    color: var(--midnight-base);
                    font-size: 5.4rem;
                    line-height: 1;
                    display: block;
                    font-family: KONE Information;
                    font-weight: 600;
                    width: 100%;
                }
            }

            #text {
                h2 {
                    font-size: 2.9rem;
                    line-height: 1;
                    font-family: KONE Information;
                    font-weight: 500;
                }
            }
        }

        #date-time {
            display: flex;
            flex-direction: column;
            line-height: 2rem;
            justify-content: center;
            font-weight: 500;
        }
    }

    td:nth-of-type(2) {
        width: 100px;
        padding-right: 0;
    }

    .station-name-cell {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &:hover {
            .station-name {
                text-decoration: underline;
            }
        }

        .weather-data {
            font-size: medium;
            height: 20px;
            display: flex;
            margin-right: 30%;
            margin-left: 2rem;
            align-items: center;

            img {
                width: 30px;
                height: 30px;
            }

            span {
                margin-left: 1rem;
                width: 50px;
            }
        }
    }

    .station-health {
        padding-left: 3.5rem;

        > div {
            display: flex;
            align-items: center;
        }

        &.alert {
            color: var(--warning-orange);
            svg {
                width: 1em;
                height: 1em;
                fill: var(--warning-orange);
                margin-left: 1em;
            }
        }
    }

    #weather-info {
        margin-top: 1rem;
        font-size: small;
        display: flex;
        align-items: center;

        a {
            img {
                margin-left: 1em;
                height: 1.5em;
            }
        }
    }
}
