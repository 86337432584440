.pillContainer {
    cursor: pointer;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    text-transform: uppercase;
    position: relative;

    .pill {
        display: inline-flex;
        padding: 8px 1rem;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-radius: 8px;

        &.secondary {
            background-color: var(--dark-grayscale-200-inactive);
            color: var(--blue-70);
        }

        &.active {
            background-color: var(--blue-70);
            color: var(--light-blue-base);

            &.secondary {
                background-color: var(--kone-blue);
                color: var(--blue-70);
            }
        }

        &.disabled {
            color: gray;
            &.active {
                background-color: rgba(48, 48, 48, 0.342);
            }
        }
    }

    .alert-notification {
        position: absolute;
        top: -2px;
        right: -2px;

        width: 10px;
        height: 10px;

        background-color: red;
        border-radius: 10px;
    }
}
