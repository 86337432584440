#root,
.App {
    height: 100vh;
    overflow: auto;
}

a {
    color: var(--text-base);
}

#main-container {
    display: flex;

    .main-view-container {
        flex-grow: 1;
        position: relative;
        overflow: auto;
    }
}
