#sidebar {
    height: 100%;
    background: var(--dark-grayscale-700-bg-secondary);
    width: 28rem;
    position: relative;
    overflow: auto;

    .sidebar-title {
        background: var(--dark-grayscale-800-bg-primary);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem;

        h2 {
            font-size: 2.4rem;
            letter-spacing: 1px;
            margin-bottom: 0.8rem;
            text-transform: capitalize;
            font-weight: 400;
            display: flex;
            align-items: center;
            svg {
                margin-right: 0.5rem;
            }
        }
        h3 {
            font-size: 1.8rem;
            font-weight: 400;
            text-transform: uppercase;
        }
    }

    .sidebar-stats {
        margin: 0.5rem 1rem;
        background-color: var(--dark-grayscale-600-bg-tertiary, #21242d);
        padding: 16px;
        border-radius: 8px;
        margin-bottom: 24px;

        &:last-of-type {
            margin-bottom: 0;
        }

        .stats-label {
            display: flex;
            justify-content: space-between;
            font-size: 1.8rem;
            padding-bottom: 0.5rem;
            border-bottom: 2px solid white;
            margin-bottom: 1rem;
        }

        .stats-container {
            font-size: 2.4rem;

            label {
                font-size: 1.2rem;
            }

            .sidebar-vertical-split {
                display: flex;
                justify-content: space-between;
                gap: 10%;
            }
        }
    }
}
