.label {
    display: inline-block;
    &::first-letter {
        text-transform: capitalize;
    }
}

.numeric-input-container {
    display: flex;
    align-items: center;

    .numeric-input {
        display: flex;
        align-items: center;
        border: 1px solid white;
        border-radius: 6px;
        width: fit-content;
        padding: 4px 8px;

        input {
            border: 0 !important;
            &:focus-visible {
                border: 0 !important;
                outline: none;
            }
        }

        > div {
            cursor: pointer;
            user-select: none;
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(255, 255, 255, 0.34);
            border-radius: 4px;
        }
    }

    .after {
        display: inline-block;
        margin-left: 1rem;
    }
}
