/*========================================================================
*      SCSS Styles for StatsSidebar                                       
=========================================================================*/

#statsSidebar {
    height: 100%;
    background: var(--dark-grayscale-700-bg-secondary);
    width: 28rem;
    position: relative;
    overflow: auto;

    .title {
        background: var(--dark-grayscale-800-bg-primary);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem;

        h2 {
            font-size: 2.4rem;
            letter-spacing: 1px;
            margin-bottom: 0.8rem;
            text-transform: capitalize;
            font-weight: 400;
            display: flex;
            align-items: center;
            svg {
                margin-right: 0.5rem;
            }
        }
        h3 {
            font-size: 1.8rem;
            font-weight: 400;
            text-transform: uppercase;
        }
    }

    .stats {
        padding: 0.5rem 1rem;

        .statsContent {
            .stats-group {
                background-color: var(--dark-grayscale-600-bg-tertiary, #21242d);
                padding: 16px;
                border-radius: 8px;
                margin-bottom: 24px;

                .stats-data {
                    margin-bottom: 24px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    > label {
                        display: flex;
                        justify-content: space-between;
                        font-size: 1.8rem;
                        padding-bottom: 0.5rem;
                        border-bottom: 2px solid white;
                    }

                    .data-group {
                        margin-top: 1rem;
                        display: flex;
                        font-size: 2.4rem;
                        justify-content: space-between;

                        label {
                            font-size: 1.2rem;
                        }

                        &.station-overview-data-group {
                            // display: block;
                            flex-direction: column;
                        }

                        .station-overview-data {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            margin-top: 1rem;

                            > span {
                                display: flex;
                                width: 40px;

                                &.incoming {
                                    justify-content: space-between;
                                    width: 85px;
                                }
                                &.outgoing {
                                    justify-content: end;
                                    width: 85px;
                                }
                            }
                        }

                        .data {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            span {
                                margin-top: 1rem;
                            }
                        }

                        .platform-wt {
                            > label {
                                margin-top: 0;
                                margin-bottom: 1rem;
                                display: block;
                            }

                            > div {
                                display: flex;

                                > .data:first-of-type {
                                    margin-right: 1rem;
                                }
                            }
                        }
                    }

                    .DateTimePicker-container {
                        display: flex;
                        gap: 2rem;

                        .DatePicker-container {
                            margin-top: 24px;
                        }
                    }

                    .go-button-container {
                        margin-top: 2rem;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        > button {
                            display: flex;
                            align-items: center;
                            text-transform: uppercase;
                            background-color: var(--kone-blue);
                            color: white;
                            padding: 0.5rem 1rem;
                            border-radius: 4px;

                            &:hover {
                                background-color: var(--kone-blue-80);
                            }

                            &.disabled {
                                background-color: var(--kone-blue-40);
                            }

                            > svg {
                                margin-right: 1rem;
                            }
                        }
                    }

                    .alert {
                        display: flex;
                        color: var(--warning-orange);
                        margin-top: 2rem;
                        align-items: center;
                        min-height: 30px;

                        &.live {
                            > svg {
                                animation: alert-icon-pulse infinite 2s ease-in-out;
                            }
                        }

                        > svg {
                            fill: var(--warning-orange);
                            margin-right: 1rem;
                        }

                        > span {
                            width: 100%;
                            display: flex;
                            justify-content: flex-end;
                            margin-right: 2rem;
                        }
                    }
                }
            }
        }
    }
}

@keyframes alert-icon-pulse {
    0% {
        width: 24px;
        height: 24px;
    }
    50% {
        width: 30px;
        height: 30px;
    }
    100% {
        width: 24px;
        height: 24px;
    }
}
