.Overlay {
    position: fixed;
    inset: 0px;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    .Modal {
        .modal-header {
            background-color: #717a80;
            width: 100%;
            padding: 12px 32px;
            font-size: 24px;
        }
        .modal-body {
            background: var(--dark-background-secondary);
            overflow: auto;
            outline: none;
            position: relative;
            width: 672px;
            height: 372px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .modal-text {
                text-align: center;
                margin-bottom: 2rem;
            }
            .modal-first-line {
                font-size: 24px;
            }
            .modal-second-line {
                width: 474px;
                font-size: 20px;
            }
            .modal-third-line {
                font-size: 18px;
                display: flex;
                margin-bottom: 5rem;

                .security-checkbox {
                    margin-right: 1rem;
                }
            }

            .buttons-container {
                display: flex;
                justify-content: center;
                gap: 2rem;
            }
        }
    }
}

#simulation-station-view {
    padding: 5rem;

    nav {
        padding: 0.5em 1em;
        font-size: larger;
        text-transform: uppercase;
    }

    table {
        margin-top: 3rem;

        thead {
            font-size: 24px;
            tr {
                td {
                    border-bottom: 1px solid white;
                }
            }
        }
        thead {
            font-size: 28px;
            .button-container {
                display: flex;
                justify-content: end;
            }

            tr {
                td {
                    border-bottom: 3px solid white;
                    &::first-letter {
                        text-transform: capitalize;
                    }
                }
            }
            tr:nth-of-type(2) {
                font-size: 24px;
                height: 60px;

                td {
                    border-bottom: 1px solid white;
                    vertical-align: bottom;

                    .delete-icon-container {
                        display: flex;
                        align-items: center;

                        button {
                            margin-left: 2rem;
                        }
                    }
                }
            }

            td {
                vertical-align: middle;
            }
        }

        tbody {
            tr {
                td:nth-of-type(1) {
                    width: 10%;
                    min-width: 192px;
                }
            }

            td {
                background-color: var(--dark-background-primary);

                &.FINISHED {
                    color: var(--accent-accent-green, #1ed273);
                }
                &.FAILED {
                    color: var(--accent-accent-red, #ff5f28);
                }
            }
        }
    }
}
