.timeline {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    .play-stop-button {
        margin-right: 2rem;
        background-color: transparent;
        color: white;
        cursor: pointer;
        padding: 0 0.5rem;
        z-index: 2;
    }

    .data {
        display: flex;
        flex-direction: column;
        width: 92%;

        .labels {
            display: flex;
            justify-content: space-between;

            /* > span {
                margin: 0 16px;
            } */
        }

        .axis {
            position: relative;
            width: 100%;
            height: 4px;
            border-radius: 0.5rem;
            background: rgba(158, 158, 158, 0.5);
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            margin: 8px 0;

            > input {
                position: absolute;
                top: 0;
                left: 0;
                margin: 0;
                -webkit-appearance: none; /* Override default CSS styles */
                appearance: none;
                height: 4px; /* Specified height */
                border-radius: 0.5rem;
                outline: none; /* Remove outline */

                &::-webkit-slider-thumb {
                    -webkit-appearance: none; /* Override default look */
                    appearance: none;
                    width: 18px; /* Set a specific slider handle width */
                    height: 18px; /* Slider handle height */
                    background-color: var(--kone-blue);
                    border: 4px solid white;
                    cursor: pointer; /* Cursor on hover */
                    border-radius: 50%;
                }

                &::-moz-range-thumb {
                    width: 1rem; /* Set a specific slider handle width */
                    height: 1rem; /* Slider handle height */
                    background-color: white;
                    cursor: pointer; /* Cursor on hover */
                    border-radius: 50%;
                }
            }
        }

        .go-live-button-wrapper {
            display: flex;
            justify-content: end;

            .go-live-button {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                background-color: var(--kone-blue);
                color: white;
                padding: 0.5rem 1rem;
                border-radius: 4px;

                &:hover {
                    background-color: var(--kone-blue-80);
                }

                &.disabled {
                    background-color: var(--kone-blue-40);
                }

                > svg {
                    margin-right: 1rem;
                }
            }
        }
    }
}
