.dashboard-container {
    width: 100%;
    padding: 0rem 2rem 0 2rem;
    margin-top: 7rem;
    .dashboard-heading {
        color: #f0f3f5;
        padding: 0.625rem;
        margin-top: 0.625rem;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.4rem;
        font-weight: 600;
        margin-right: 0.625rem;
    }

    .widget-top-row {
        padding-top: 4rem;
        flex: 1;
        width: 20.4rem;
        height: 14rem;
        flex-shrink: 0;
        background: var(--dark-background-primary, #11161a);
        .title {
            /* Heading/H4 */
            justify-content: center;
            display: flex;
            color: var(--dark-grayscale-100, #fff);
            font-family: KONE Information;
            font-size: 2rem;
            font-weight: 400;
            line-height: 2.25rem; /* 150% */
        }
        .values {
            justify-content: center;
            display: flex;
            color: var(--dark-grayscale-100, #fff);
            /* Heading/H4 */
            font-family: KONE Information;
            font-size: 2.5rem;
            font-weight: 400;
            line-height: 2.55rem; /* 150% */
            .small-icon {
                font-size: 0.9em;
            }
        }
    }

    .row {
        justify-content: center;
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 1rem;
        border-collapse: separate;
        border-spacing: 3rem;
        gap: 5rem;
        padding-top: 2rem;
    }
    .row_gap {
        display: flex;
        flex-direction: row;
        border-collapse: separate;
        border-spacing: 3rem;
        gap: 0.5rem;
    }
    .margin_gap_5 {
        margin-left: 5rem;
    }
    .margin_gap_20 {
        margin-left: 35rem;
    }
}
