/*========================================================================
*      SCSS Styles for MainSidebar                                       
=========================================================================*/

.mainSidebar {
    // border: 1px solid #cde7ff;
    display: flex;
    flex-direction: column;
    width: 18rem;
    min-height: 92.5vh;
    background: var(--dark-grayscale-400, #11161a);
    top: 0;
    left: 0;

    &.reduced {
        width: 80px;
    }

    // * {
    //     border: 1px solid #cde7ff;
    // }

    /* #brand {
        padding: 2rem;
        svg {
            width: 8rem;
            height: 5rem;
            margin-bottom: 1rem;
        }
        h2 {
            font-size: 2.2rem;
            font-weight: 400;
        }
    } */
    nav {
        position: relative;

        ul {
            li {
                font-size: 1.6rem;
                list-style-type: none;
                padding: 16px 26px;
                margin: 2px 0;
                position: relative;
                background-color: var(--dark-background-secondary, #11161a);

                .main-sidebar-element {
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    span.icon {
                        margin-right: 1rem;
                        svg {
                            width: 2rem;
                            height: 2rem;
                            position: relative;
                        }
                    }
                }

                a {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    span.icon {
                        margin-right: 1rem;
                        svg {
                            width: 2rem;
                            height: 2rem;
                            position: relative;
                        }
                    }
                }

                &.active {
                    background: var(--dark-grayscale-800-bg-primary);
                    ::after {
                        content: '';
                        position: absolute;
                        background: var(--kone-blue);
                        width: 0.4rem;
                        height: 100%;
                        top: 0;
                        right: 0;
                    }
                }

                &:last-of-type {
                    padding: 0;
                    position: relative;
                }

                .reducer {
                    position: absolute;
                    right: -16px;
                    height: 35px;
                    width: 50px;
                    background-color: var(--dark-grayscale-400, #11161a);
                    border-radius: 8px;
                    z-index: 2;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    &.reverse {
                        rotate: 180deg;
                    }
                }
            }
        }
    }
}
