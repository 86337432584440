/*========================================================================
*      SCSS Styles for StationTwin                                       
=========================================================================*/
main {
    .dashboard-container {
        width: 100%;
        padding: 0rem 2rem 0 2rem;
        .dashboard-heading {
            color: #f0f3f5;
            padding: 0.625rem;
            margin-top: 0.625rem;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 1.4rem;
            font-weight: 600;
            margin-right: 0.625rem;
        }

        .widget-top-row {
            padding-top: 4rem;
            flex: 1;
            width: 20.4rem;
            height: 14rem;
            flex-shrink: 0;
            background: var(--dark-background-primary, #11161a);
            .title {
                /* Heading/H4 */
                justify-content: center;
                display: flex;
                color: var(--dark-grayscale-100, #fff);
                font-family: KONE Information;
                font-size: 2rem;
                font-style: normal;
                font-weight: 400;
                line-height: 2.25rem; /* 150% */
            }
            .values {
                justify-content: center;
                display: flex;
                color: var(--dark-grayscale-100, #fff);
                /* Heading/H4 */
                font-family: KONE Information;
                font-size: 2.5rem;
                font-style: normal;
                font-weight: 400;
                line-height: 2.55rem; /* 150% */
                .small-icon {
                    font-size: 0.9em;
                }
            }
        }

        .widget-chart {
            padding: 2rem 3rem;
            flex: 1;
            background: var(--dark-background-primary, #11161a);
            .title {
                /* Heading/H4 */
                color: var(--dark-grayscale-100, #fff);
                font-size: 24px;
            }
        }

        .widget-chart-metro {
            padding-top: 1rem;
            width: 40rem;
            min-height: 50rem;
            flex-shrink: 0;
            flex: 1;
            background: var(--dark-background-primary, #11161a);
            .title {
                /* Heading/H4 */
                padding-top: 1rem;
                padding-left: 1rem;
                color: var(--dark-grayscale-100, #fff);
                font-family: KONE Information;
                font-size: 2rem;
                font-style: normal;
                font-weight: 400;
                line-height: 2.25rem; /* 150% */
            }
            .chart {
                margin-top: -6rem;
                display: flex;
                justify-content: flex-start;
                height: 50rem;
                width: 20rem;
            }
        }

        .barChart {
            height: 300px;
            width: 450px;
        }

        .donutChart {
            height: 300px;
            width: 450px;
        }
        .halfDonut {
            height: 300px;
            width: 450px;
        }

        .gaugeChart {
            height: 100px;
            width: 250px;
            margin-top: -70px;
        }

        .lineChart {
            height: 300px;
            width: 450px;
        }

        .areaChart {
            height: 300px;
            width: 450px;
        }

        .stackedareaChart {
            height: 300px;
            width: 450px;
        }

        .row {
            justify-content: center;
            width: 100%;
            display: flex;
            flex-direction: row;
            padding: 1rem;
            border-collapse: separate;
            border-spacing: 3rem;
            gap: 5rem;
            padding-top: 2rem;
        }
        .row_gap {
            display: flex;
            flex-direction: row;
            border-collapse: separate;
            border-spacing: 3rem;
            gap: 0.5rem;
        }
        .margin_gap_5 {
            margin-left: 5rem;
        }
        .margin_gap_20 {
            margin-left: 35rem;
        }
    }
}
