/*========================================================================
*      SCSS Styles for StationTwin                                       
=========================================================================*/

#stationTwin {
    height: 92.5vh;

    main {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;

        .stationInterface {
            flex: 1;
            position: relative;
            background-color: var(--dark-background-secondary);

            .top-left-corner {
                display: flex;
                flex-direction: column;
                top: 2rem;
                left: 2rem;
                position: absolute;
                z-index: 1;
                align-items: start;

                > * {
                    margin-bottom: 2rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .equipment-stats {
                    background-color: var(--dark-grayscale-400, #21242d);
                    padding: 16px;
                    border-radius: 8px;
                    font-size: 16px;
                    min-width: 160px;

                    > label {
                        display: flex;
                        justify-content: space-between;
                        font-size: 1.8rem;
                        padding-bottom: 0.5rem;
                        border-bottom: 2px solid white;
                    }

                    ul {
                        li {
                            margin-top: 1rem;
                            display: flex;
                            justify-content: space-between;

                            .stat {
                                margin-right: 2rem;
                            }

                            .visit-info-panel {
                                font-size: 1.3rem;
                                margin-left: 1rem;
                                &.visit-info-panel-divider {
                                    margin-top: 1rem;
                                    border-top: 1px dashed white;
                                }
                            }
                        }
                    }
                }
            }

            .top-right-corner {
                display: flex;
                flex-direction: column;
                top: 2rem;
                right: 2rem;
                position: absolute;
                z-index: 1;
                align-items: end;

                .density-map {
                    width: 100px;
                    table {
                        tr {
                            td {
                                padding-left: 1rem;

                                &.color {
                                    width: 1rem;
                                    height: 2rem;
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.histogram {
    margin: 0.4rem 0;
    border: 1px solid yellow;
    display: flex;
    align-items: end;
    justify-content: space-between;

    .bar {
        background-color: green;
    }
}

.live-dot-container {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    font-size: x-small;
    position: relative;

    .latency {
        position: absolute;
        right: 30px;
        width: max-content;
    }

    .live-dot {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: var(--success-green);
        opacity: 0.3;
        margin-left: 1rem;

        &.disabled {
            background-color: var(--dark-grayscale-200-inactive);
            padding-right: 0;
        }

        &.live {
            animation: pulse 2s ease-in-out infinite;
        }
    }
}

@keyframes pulse {
    0% {
        opacity: 0.3;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.3;
    }
}
