.checkbox-container {
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: auto;
    height: 16px;

    input {
        z-index: 10;
        position: relative;
        opacity: 0;
        cursor: pointer;
        height: 16px;
        width: 16px;
        margin: 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: white;
        border-radius: 2px;
    }

    &:hover input ~ .checkmark {
        background-color: #bbbbbb;
    }

    input:checked ~ .checkmark {
        background-color: var(--kone-blue);
    }

    :after {
        content: '';
        position: absolute;
        display: none;
        left: 5px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    input:checked ~ .checkmark:after {
        display: block;
    }
}
