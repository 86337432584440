.react-toggle {
    .react-toggle-track {
        background-color: white;
        height: 20px;
        width: 32px;
    }

    &.react-toggle--checked .react-toggle-track {
        background-color: var(--kone-blue);
    }

    &:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: white;
    }

    &.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: var(--kone-blue);
    }

    .react-toggle-thumb {
        background-color: black;
        border: none;
        width: 14px;
        height: 14px;
        top: 3px;
        left: 3px;
    }
    &.react-toggle--checked .react-toggle-thumb {
        background-color: white;
        border: none;
        top: 3px;
        right: 3px;
        left: auto;
    }

    &.react-toggle--focus .react-toggle-thumb {
        box-shadow: none;
    }
    &:active:not(:disabled) .react-toggle-thumb {
        box-shadow: none;
    }
}

.react-toggle-thumb {
    transition: all 1.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
