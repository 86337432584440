:root {
    --kone-blue: #1450f5;

    --blue-600: #1444c8;
    --blue-400: #4373f7;
    --blue-300: #7296f9;
    --blue-70: #e7edfe;

    --grey-500: #959595;

    --surface_blue_secondary: #f3f6fe;

    --green-base: #1ed273;
    --red-base: #ff5f28;

    /* OLD COLORS */

    --kone-blue-80: #4373f7;
    --kone-blue-60: #7296f9;
    --kone-blue-40: #a1b9fb;
    --kone-blue-20: #d0dcfd;

    --light-blue-base: #2d71b4;

    --light-background-blue: #0084ff1a;

    --light-Grayscale-100: #11161a;
    --dark-background-primary: #11161a;

    --Dark-Grayscale-200: #aaafb3;
    --dark-grayscale-200-inactive: #83868e;
    --dark-grayscale-400: #262e33;
    --dark-grayscale-600-bg-tertiary: #21242d;
    --dark-grayscale-700-bg-secondary: #1b1e26;
    --dark-grayscale-800-bg-primary: #141414;

    --dark-background-secondary: #1c2226;

    --midnight-base: #021020;
    --midnight-highlight: #0c2133;
    --text-base: #fff;
    --success-green: #1ed273;
    --accent-accent-green: #1ed273;
    --accent-accent-red: #ff5f28;
    --warning-orange: #ffa023;
    --agent-out: #f14dff;
    --agent-in: #65d2d1;
    --error: rgb(255, 95, 40);

    --metro-train-orange: #e06325;
    --metroLine-gray: #696969;
}
