/*========================================================================
*      SCSS Styles for FloorNavigator                                       
=========================================================================*/

#floorNavigator {
    position: fixed;
    right: 0;
    bottom: 10%;
    z-index: 900;

    ul {
        display: flex;
        flex-direction: column;
        padding-right: 4rem;
        &:hover {
            & > li {
                opacity: 0.7;
            }
        }
        li {
            list-style-type: none;
            font-size: 2rem;
            margin: 1.2rem 0;
            min-width: 17rem;
            opacity: 0.4;
            text-transform: uppercase;
            svg {
                margin-right: 1rem;
                transition: all 0.4s ease-in-out;
                &.rotate {
                    transform: rotate(180deg);
                }
            }

            &:hover {
                cursor: pointer;
                opacity: 1;
            }

            &.active {
                margin: 3rem 0;
                font-size: 2.4rem;
                font-weight: 500;
                // color: #fff;
                // text-shadow: 0 0 4px #1380bf, 0 0 11px #1380bf, 0 0 19px #1380bf, 0 0 40px #1380bf,
                //     0 0 80px #1380bf, 0 0 90px #1380bf, 0 0 100px #1380bf, 0 0 150px #1380bf;
                opacity: 1;
                svg {
                    width: 2rem;
                    height: 2rem;
                    position: relative;
                    filter: drop-shadow(0 0 15px #1380bf);
                    filter: drop-shadow(0 0 5px #3aa4e2);
                }
            }

            &.equipments {
                display: flex;
                align-items: center;
            }

            &.not-visible {
                opacity: 0;
                height: 0;
                margin: 0;
            }

            transition: all 0.4s ease-in-out;
        }
    }
}
