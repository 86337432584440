.metroline-container {
    position: relative;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.M1-east {
        justify-content: start;
    }

    &.M2-east {
        justify-content: space-between;
    }

    .point {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: white;
        border: 3px solid var(--metroLine-gray);
        z-index: 2;

        &.right {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 0;
        }
        &.left {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 0;
        }

        &.hidden {
            visibility: hidden;
        }
    }

    .lines-container {
        position: absolute;
        display: flex;

        &.wide {
            width: 100%;
            justify-content: space-between;
        }

        .single-line-container {
            position: relative;
            width: 20px;
            display: flex;
            justify-content: center;

            .line {
                width: 8px;
                height: 60px;
                background-color: var(--metroLine-gray);

                &.east-end {
                    background-color: transparent;
                    background: linear-gradient(
                        var(--metroLine-gray) 0%,
                        var(--metroLine-gray) 50%,
                        transparent 50%,
                        transparent 100%
                    );
                }
                &.west-end {
                    background-color: transparent;
                    background: linear-gradient(
                        transparent 0%,
                        transparent 50%,
                        var(--metroLine-gray) 50%,
                        var(--metroLine-gray) 100%
                    );
                }
                &.myllypuro-connection {
                    background-color: transparent;
                    background: linear-gradient(
                        transparent 0%,
                        transparent 34%,
                        var(--metroLine-gray) 34%,
                        var(--metroLine-gray) 100%
                    );
                }
            }

            .middle-point {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 12px;
                height: 12px;
                background-color: var(--metroLine-gray);
                border-radius: 50%;
                z-index: 3;

                &.west {
                    margin-top: 54px;
                }
                &.east {
                    margin-top: -6px;
                }

                &.west-itäkeskus-left {
                    margin-right: 36px;
                }
                &.west-itäkeskus-right {
                    margin-left: 36px;
                }
                &.east-myllypuro {
                    margin-right: 44px;
                }
                &.east-puotila {
                    margin-left: 44px;
                    margin-top: -186px;
                }
            }

            .rounded-connection-container {
                position: absolute;
                width: 48px;
                height: 60px;
                top: 30px;
                left: 6px;
                display: flex;
                flex-direction: column;

                &.inverted {
                    transform: scaleX(-1);
                    left: auto;
                    right: 6px;
                }

                > div {
                    height: 20px;
                }
                .top-part {
                    position: relative;
                    .top-connection {
                        position: absolute;
                        background-color: transparent;
                        border-radius: 50%;
                        width: 40px;
                        height: 40px;
                        top: -10px;
                        left: 0px;
                        border: 8px solid transparent;
                        border-bottom-color: var(--metroLine-gray);
                        transform: rotate(80deg);
                    }
                }
                .middle-part {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .middle-connection {
                        width: 8px;
                        background-color: var(--metroLine-gray);
                        height: 34px;
                        transform: rotate(-65deg);
                    }
                }
                .bottom-part {
                    position: relative;
                    height: 20px;
                    top: -10px;
                    overflow: hidden;
                    .bottom-connection {
                        position: absolute;
                        background-color: transparent;
                        border-radius: 50%;
                        width: 40px;
                        height: 40px;
                        bottom: -20px;
                        right: -1px;
                        border: 9px solid transparent;
                        border-top-color: var(--metroLine-gray);
                        transform: rotate(80deg);
                    }
                }
            }
        }
    }
}

.train-final {
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: var(--metro-train-orange);
    border-radius: 0 50% 50% 50%;
    z-index: 4;

    &:before {
        position: absolute;
        content: '';
        display: block;
        border-top: 4px solid var(--metro-train-orange);
        border-left: 4px solid var(--metro-train-orange);
        top: -6px;
        left: -6px;
        width: 14px;
        height: 14px;
        z-index: 4;
    }

    &.middle {
        animation: train-pulse 2s ease-in-out infinite;
    }

    &.west {
        rotate: (45deg);
        bottom: -2px;
        &.middle {
            margin-bottom: -2px;
        }
    }

    &.east {
        rotate: (-135deg);
        top: -2px;
        &.middle {
            margin-top: -2px;
        }
    }
}

@keyframes train-pulse {
    0% {
        transform: scale(1);
        opacity: 0.6;
    }
    50% {
        transform: scale(0.8);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0.6;
    }
}
