.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.spinner {
    position: relative;
    border: 6px solid var(--blue-70);
    border-top: 6px solid var(--kone-blue); /* Adjust the color as needed */
    border-right: 6px solid var(--kone-blue);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite; /* Spinning animation */
    margin-bottom: 16px;

    &::after {
        content: '';
        position: absolute;
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: var(--kone-blue);
        top: 2px;
        left: 1px;
    }

    &::before {
        content: '';
        position: absolute;
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: var(--kone-blue);
        bottom: 2px;
        right: 1px;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 1px solid red;
    background: radial-gradient(farthest-side, #ffa516 94%, #0000) top/8px 8px no-repeat,
        /* conic-gradient(#0000 30%, #ffa516) */;
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
    // animation: l13 1s infinite linear;
}
@keyframes l13 {
    100% {
        transform: rotate(1turn);
    }
}
