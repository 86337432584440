#new-simulation-view {
    padding: 5rem 6.5rem;

    nav {
        margin: 4rem 0 3rem;
        font-size: 2rem;
        text-transform: uppercase;
    }

    .grid-3 {
        display: grid;
        grid-template-columns: 3fr 3fr 2fr;
        column-gap: 4%;
        margin-bottom: 2rem;

        .title {
            font-size: larger;
            padding-bottom: 1rem;
        }
    }

    .Collapsible {
        margin-bottom: 2rem;
    }

    .Collapsible__trigger {
        display: block;
        padding: 1rem 0;
        cursor: pointer;
        font-size: larger;
        padding: 1rem;
        background-color: var(--dark-grayscale-800-bg-primary);
        border-radius: 4px;

        .trigger {
            display: flex;
            justify-content: space-between;
            div {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .Collapsible__contentInner {
        padding: 2rem 1rem;
        background-color: var(--dark-grayscale-400);
        border-radius: 4px;
        margin-top: 4px;

        .users-section {
            .users-layout {
                display: flex;
                flex-wrap: wrap;
                &.horizontal {
                    flex-direction: row;
                    gap: 2rem;
                }
                &.vertical {
                    flex-direction: column;
                }
                &.lv-0 {
                    gap: 2rem;

                    > .content {
                        border-top: 1px solid white;
                        padding-top: 2rem;
                        &:first-of-type {
                            border: none;
                            padding: 0;
                        }
                    }
                }
                &.lv-1 {
                    gap: 14px;
                }

                .layout-label {
                    display: inline-block;
                    margin-bottom: 1rem;
                    &.lv-1 {
                        font-size: 24px;
                    }
                    &.lv-2 {
                        font-size: 20px;
                    }
                    &.lv-3 {
                        font-size: 14px;
                    }
                }
            }
        }

        .equipments-container {
            width: 100%;

            .elevators-section {
                display: flex;
                align-items: center;

                .elevator-detail {
                    display: flex;
                    margin-right: 4rem;
                    align-items: center;

                    .elevator-detail-title {
                        margin-right: 2rem;
                    }

                    .elevator {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .toggle-with-margin {
                        margin: 0 1rem;
                    }

                    .span-off {
                        color: var(--grey-500);
                    }
                }
            }

            .escalators-section {
                display: flex;
                flex-wrap: wrap;

                .escalator-detail {
                    margin-right: 2rem;
                    margin-bottom: 2rem;

                    .escalator-detail-first-row {
                        display: flex;
                        margin-bottom: 1rem;

                        .escalator-detail-title {
                            margin-right: 2rem;
                        }
                    }

                    .escalator-direction-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 86px;
                        height: 48px;
                        border-radius: 4px;
                        background-color: var(--dark-background-secondary);
                        gap: 4px;

                        .escalator-direction {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 32px;
                            height: 32px;
                            border-radius: 4px;
                            background-color: #797d80;
                            cursor: pointer;

                            &.rotate {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }

            .equipments-details {
                margin-bottom: 2rem;

                &.elevators {
                    margin-bottom: 5rem;
                }

                .equipment-title {
                    font-size: x-large;
                    text-transform: uppercase;
                    min-width: 120px;
                    margin-bottom: 2rem;
                }
            }
        }

        .schedules-container {
            display: grid;
            grid-template-columns: 1fr 1fr;

            .schedules-side {
                border-right: 1px solid white;

                &:last-of-type {
                    border-right: none;
                    padding-left: 2rem;
                }

                .schedule-side-title {
                    display: inline-block;
                    text-transform: uppercase;
                    font-size: x-large;
                    margin-bottom: 2rem;
                }

                .schedules-elements {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 2rem;
                    .schedules {
                        display: flex;
                        align-items: start;
                        flex-direction: column;
                        gap: 2rem;

                        .schedules-title {
                            display: inline-block;
                            text-transform: uppercase;
                            font-size: large;
                        }
                    }
                }
            }
        }
    }

    .last-line {
        display: flex;
        align-items: center;
        margin: 2rem 0;

        input {
            margin: 0 2rem;
            width: 300px;
            padding: 1rem 2rem;
        }
    }
}

.grid-element {
    label {
        display: block;
        margin-bottom: 2rem;
        font-size: larger;
        height: 1em;
    }
}
